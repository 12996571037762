import {useEffect, useState} from "react";
import config from "../i18n.common";

export interface Config {
    userIp: string,
    isMobile: boolean,
    userCountryId: number,
    userCountryName: string,
    userCountryCode2: string | null,
    userCurrencyId: string,
    userPhonePrefix: string,
    siteAvailable: boolean,
    thisIsMirror: boolean,
}

export const useLanguageHook = () => {
    const [config, setConfig] = useState<any>();
    const [configError, setConfigError] = useState(null);
    const [layoutLoaded, setLayoutLoaded] = useState<boolean>(false)

    const [lang, setLang] = useState<{code: string}[]>([]);
    const [langError, setLangError] = useState(null);

    const detectDefaultLocale = (supportedLocales: string[], userCountryCode: string|null): string => {
        // Try to detect by browser language
        // for (const browserLocale of getBrowserLocales()) {
        //     if (supportedLocales.includes(browserLocale.toLowerCase())) {
        //         return browserLocale.toLowerCase();
        //     }
        // }

        if (userCountryCode) {
            for (const supportedLocale of supportedLocales) {
                if (supportedLocale.endsWith(`-${userCountryCode.toLowerCase()}`)) {
                    return supportedLocale;
                }
            }
        }

        return 'en';
    }

    useEffect(() => {
        fetch('https://oshcasino.com/api/configs')
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network error');
                }
                return response.json();
            })
            .then((data) => {
                setConfig(data);
            })
            .catch((error) => {
                setConfigError(error.message);
            });
    }, []);

    useEffect(() => {
        fetch('https://oshcasino.com/api/classifiers/languages/get')
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network error');
                }
                return response.json();
            })
            .then((data) => {
                setLang(data?.payload.items || []);
            })
            .catch((error) => {
                setLangError(error.message);
            });
    }, []);
    useEffect(() => {
        // if failed to load available locales
        if (!lang || !config) {
            return;
        }

        const pathParts = window.location.pathname.split('/').filter(Boolean);

        //Redirect br-pt to pt-br
        if (pathParts[0] && pathParts[0] === 'br-pt') {
            const urlLocale = 'pt-br';
            const newPathParts = [urlLocale, ...pathParts.slice(1)];

            const newPath = `/${newPathParts.join('/')}${window.location.search}`;
            window.history.pushState({}, '', newPath);
            setLayoutLoaded(true)
            return;

        }

        // Locale defined in url
        const urlLocale = lang.find(item => item.code === pathParts[0])?.code || null;
        if (urlLocale) {
            localStorage.setItem('selectedLanguage', urlLocale);
            setLayoutLoaded(true)
            return;
        }

        // No locale in url
        const savedLocale = lang.find(item => item.code === localStorage.getItem('selectedLanguage'))?.code || null;
        if (savedLocale) {
            const newPathParts = [savedLocale, ...pathParts];
            const newPath = `/${newPathParts.join('/')}${window.location.search}`;
            window.history.pushState({}, '', newPath);
            setLayoutLoaded(true)
        } else {
            const defLocale = detectDefaultLocale(
                lang.map(locale => locale.code),
                config?.userCountryCode2 || null
            );

            const newPathParts = [
                defLocale,
                ...pathParts
            ];
            const newPath = `/${newPathParts.join('/')}${window.location.search}`;
            localStorage.setItem('selectedLanguage', defLocale);
            window.history.pushState({}, '', newPath);
            setLayoutLoaded(true)
        }
    }, [lang, config]);

    return {
        layoutLoaded
    }
}
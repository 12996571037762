import React, {useCallback, useEffect, useState} from 'react';
import './App.scss';
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import HttpApi from 'i18next-http-backend'
import MultiLoadBackendAdapter from 'i18next-multiload-backend-adapter'
import config from './i18n.common'
import 'react-before-after-slider-component/dist/build.css';
import Wheel from "./wheel/wheel";
import {useLanguageHook} from "./lang/lang";

declare global {
    interface Window {
        grecaptcha: any;
        sg: {
            launch: any
        };
    }
}
const savedLanguage = window.location.pathname.split('/').filter(Boolean)[0] ?? localStorage.getItem('selectedLanguage');
i18n.use(initReactI18next)
    .use(MultiLoadBackendAdapter)
    .init({
        //defaultNS: 'web',
        keySeparator: false,
        ns: config.ns,
        lowerCaseLng: true,
        interpolation: {
            escapeValue: false,
        },
        backend: {
            backend: HttpApi,
            backendOption: {
                loadPath: 'https://api.oshcasino.com/api/translations/{{ns}}',
                customHeaders: () => ({
                    'accept-language': `${i18n.language}`,
                    // ...
                }),
                parse: (dataString: any, language: any) => {
                    const data = JSON.parse(dataString)
                    const lang = language[0]
                    return {[lang]: data};
                }
            }
        },
        lng: savedLanguage,
        updateMissing: true,
        nsSeparator: ':',
        fallbackLng: "en",
        ignoreJSONStructure: true
    }, (err, t) => {
        if (err) {
            console.error('i18next error:', err);
        }
    });

function App() {
    const {layoutLoaded} = useLanguageHook()

  return (
      <div>
          {!layoutLoaded && (
              <div className="loader">
                  <img src="/logo-loader.png"></img>
              </div>
          )}
          {layoutLoaded && (
              <Wheel/>
          )}
      </div>
  );
}

export default App;
